<script setup>
import { computed, ref, watch } from 'vue';
import { useEncounters } from '@/composables/use-encounters';
import { useRoute, useRouter } from 'vue-router';

const emit = defineEmits(['encounter-changed']);

const { encounters } = useEncounters();
const route = useRoute();
const router = useRouter();

const selectedEncounter = ref(route.query.encounter);

const encountersOptions = computed(() => {
  return encounters.value?.map((encounter) => {
    let selected = false;
    if (route.query.encounter === encounter.id) {
      selected = true;
    }
    return {
      label: encounter.formattedCreatedAt,
      value: encounter.id,
      selected,
    };
  });
});

const hasMultipleEncounters = computed(() => {
  return encounters.value?.length > 1;
});

function previous () {
  const index = encounters.value.findIndex((encounter) => encounter.id === selectedEncounter.value);
  if (index > 0) {
    selectedEncounter.value = encounters.value[index - 1].id;
  }
}

function next () {
  const index = encounters.value.findIndex((encounter) => encounter.id === selectedEncounter.value);
  if (index < encounters.value.length - 1) {
    selectedEncounter.value = encounters.value[index + 1].id;
  }
}

watch(selectedEncounter, (val) => {
  router.replace({
    query: {
      ...route.query,
      encounter: val,
    },
  });
  emit('encounter-changed', val);
}, { immediate: true });
</script>


<template>
  <!-- <pre>{{ hasMultipleEncounters }}</pre> -->
  <!-- <pre>{{ encounters }}</pre> -->
  <div v-if="hasMultipleEncounters" class="flex gap-2 justify-end items-center ">
    <div class="flex gap-2 items-center">
      <button
        class="btn btn-outline btn-primary btn-sm btn-square bg-white"
        @click="previous"
      >
        <i class="las la-angle-left text-2xl text-primary"></i>
      </button>
      <select v-model="selectedEncounter" class="select select-sm select-bordered border-primary">
        <template v-for="(encounter, index) in encountersOptions" :key="index">
          <option :value="encounter.value" :selected="encounter.selected">{{encounter.label}} {{encounter.uid}}</option>
        </template>
      </select>
      <button
        class="btn btn-outline btn-primary btn-sm btn-square bg-white"
        @click="next"
      >
        <i class="las la-angle-right text-2xl text-primary"></i>
      </button>
    </div>
  </div>
</template>

